<template>
  <div class="contract-type-form">
    <Selector
      class="contract-type-form__selector contract-type-form__input form-input"
      required
      :title="'Тип договора*'"
      :options="Object.keys(ContractType).map((key) => ({ id: key, name: ContractType[key] }))"
      :select-fitrst-item="false"
      :default-select-item-id="form.Type"
      @handleChange="(val) => changeTypeContract(val)"
    />
    <Selector
      v-if="ContractTypeKeys.MEDIATION_CONTRACT === form.Type || ContractTypeKeys.SERVICE_AGREEMENT === form.Type"
      class="contract-type-form__selector contract-type-form__input form-input"
      required
      :title="'Вид договора*'"
      :options="Object.keys(ContractVariant).map((key) => ({ id: key, name: ContractVariant[key] }))"
      :default-select-item-id="form.SubjectType"
      :select-fitrst-item="false"
      @handleChange="(val) => $emit('changeValue', { name: 'SubjectType', value: val })"
    />
    <Selector
      v-if="ContractTypeKeys.MEDIATION_CONTRACT === form.Type"
      class="contract-type-form__selector contract-type-form__input form-input"
      required
      :title="'Вид деятельности*'"
      :options="Object.keys(InteractionType).map((key) => ({ id: key, name: InteractionType[key] }))"
      :default-select-item-id="form.ActionType"
      :select-fitrst-item="false"
      @handleChange="(val) => $emit('changeValue', { name: 'ActionType', value: val })"
    />
    <Selector
      v-if="ContractTypeKeys.MEDIATION_CONTRACT === form.Type"
      class="contract-type-form__selector contract-type-form__input form-input"
      required
      :title="'Агент действует*'"
      :options="Object.keys(AgentActingForPublisherType).map((key) => ({ id: key, name: AgentActingForPublisherType[key] }))"
      :default-select-item-id="form.IsAgentActingForPublisher"
      :select-fitrst-item="false"
      @handleChange="(val) => $emit('changeValue', { name: 'IsAgentActingForPublisher', value: val })"
    />
    <Selector
      v-if="ContractTypeKeys.ADDITIONAL_AGREEMENT === form.Type"
      class="contract-type-form__selector contract-type-form__input form-input"
      required
      :title="'Дополнительное соглашение*'"
      :options="additionalAgreementOptions"
      :default-select-item-id="form.ParentMainContractId"
      limit-options-height
      :select-fitrst-item="false"
      @handleChange="(val) => $emit('changeValue', { name: 'ParentMainContractId', value: val })"
    />
  </div>
</template>

<script>
import formValidate from "@/mixin/formValidate";
import Selector from "@/components/sort/Selector";
import numberWithSpaces from '@/mixin/numberWithSpaces'
import { ContractType, ContractVariant, AgentActingForPublisherType, InteractionType, ContractTypeKeys } from "@/utils/contracts";

export default {
  name: 'ContractTypeForm',
  components: {
    Selector,
  },
  mixins: [formValidate, numberWithSpaces],
  props: {
    form: {
      type: Object,
      default: null
    },
    additionalAgreementOptions: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      ContractTypeKeys,
      AgentActingForPublisherType,
      ContractVariant,
      ContractType,
      InteractionType,
    }
  },
  methods: {
    changeTypeContract(val) {
      this.$emit('changeValue', { name: 'Type', value: val })
      this.$emit('changeValue', { name: 'SubjectType', value: null })
      this.$emit('changeValue', { name: 'ActionType', value: null })
      this.$emit('changeValue', { name: 'IsAgentActingForPublisher', value: null })
      this.$emit('changeValue', { name: 'ParentMainContractId', value: null })
    }
  },
  mounted() {
    
  }
}
</script>

<style lang="scss">
@import "@/assets/styles/helpers/variables";
@import "@/assets/styles/helpers/mixin";

.form-input--full .form-input__label {
  font-size: 1.2rem;
  line-height: 1.4rem;
  top: 0.6rem;
}

.contract-type-form {
  margin-top: 2rem;
  background: #fff;
  display: flex;
  flex-direction: column;

  .selector__content {
    background: none;
    border: 1px solid #BFC2C6;
  }

  .selector__title {
    font-size: 1.6rem;
    color: #878B93;
  }
  &__input {
    .selector__content {
      width: 100%;
    }
  }

  .button {
    height: 5rem;
  }
}
</style>
